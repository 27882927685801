<template>
  <div class='page-box'>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="queryRef.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="queryRef.phone" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="上级" prop="upAgentName">
          <a-input v-model="queryRef.upAgentName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="变动类型" prop="changeType">
          <a-select style="width: 120px" placeholder="请选择" v-model='queryRef.changeType'>
            <a-select-option :value="0">
              增加
            </a-select-option>
            <a-select-option :value="1">
              减少
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="时间" prop="dateRange">
          <a-range-picker v-model='queryRef.dateRange' format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class='info-box'>
      <div class='info-item'>佣金总额：{{total}}</div>
    </div>
    <a-table :rowKey="(record,index) => index" :loading='loading' :columns="columns" :data-source="tableData" :pagination="pagination" @change='changePage'>
      <template slot="changeType" slot-scope="text">
        {{text===0?'增加':'减少'}}
      </template>
    </a-table>
  </div>

</template>

<script>
import { agent } from '@/api'
export default {
  data: () => ({
    queryRef: {
      dateRange: [],
      changeType: undefined,
      name: '',
      phone: '',
      upAgentName: ''
    },
    total: 0,
    loading: false,
    tableData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      size: 'small',
      total: 0,
    },
    columns: [
      {
        title: '姓名',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' },
      },
      {
        title: '手机号',
        dataIndex: 'phone',
        scopedSlots: { customRender: 'phone' },
      },
      {
        title: '合伙人等级',
        dataIndex: 'levelName',
      },
      {
        title: '代理等级',
        dataIndex: 'lawLevelName',
      },
      {
        title: '上级代理',
        dataIndex: 'upAgentName',
      },
      {
        title: '变动类型',
        dataIndex: 'changeType',
        scopedSlots: { customRender: 'changeType' },
      },
      {
        title: '事件名称',
        dataIndex: 'eventName',
        scopedSlots: { customRender: 'eventName' },
      },
      {
        title: '变动金额',
        dataIndex: 'changeAmount',
        scopedSlots: { customRender: 'changeAmount' },
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
      },
    ],
  }),
  methods: {
    changePage (e) {
      this.pagination = e
      this.getTableData()
    },
    handleSearch () {
      this.getTableData()
    },
    handleReset () {
      this.$refs.formRef.resetFields()
      this.getTableData()
    },
    async getTotal () {
      const { data } = await agent.getCommissionSummary()
      this.total = data
    },
    async getTableData () {
      this.loading = true
      let beginTime = this.queryRef.dateRange && this.queryRef.dateRange.length ? this.queryRef.dateRange[0] : ''
      let endTime = this.queryRef.dateRange && this.queryRef.dateRange.length ? this.queryRef.dateRange[1] : ''
      delete this.queryRef.dateRange
      const { code, page, data, msg } = await agent.getCommissionChangeLog({
        ...this.queryRef,
        beginTime: beginTime,
        endTime: endTime,
        current: this.pagination.current,
        size: this.pagination.pageSize,
      })
      this.loading = false
      if (code === '00000') {
        this.tableData = data
        this.pagination.current = page.pageNo
        this.pagination.pageSize = page.pageSize
        this.pagination.total = page.total
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
  },
  mounted () {
    this.getTableData()
    this.getTotal()
  },
}
</script>

<style lang="less" scoped>
.info-box {
  display: flex;
  align-items: center;
  margin: 12px;
  .info-item {
    margin-right: 48px;
  }
}
.flex-a-c {
  display: flex;
  align-items: center;
}
</style>
